import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from '../../pages/Home';
import Category from '../../pages/Category';
import Detail from '../../pages/Detail';
import Trace from '../../pages/Trace';
import LegalNotice from '../../pages/LegalNotice';
import GPSTest from '../../pages/GPSTest';

function Router(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/kategorie/:id" component={Category} />
      <Route exact path="/detail/:id" component={Detail} />
      <Route exact path="/route/:id" component={Trace} />
      <Route exact path="/impressum" component={LegalNotice} />
      <Route exact path="/gps-test" component={GPSTest} />
    </Switch>
  );
}

export default withRouter(Router);
