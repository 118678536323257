import {
  BreadcrumbState,
  BreadcrumbActionTypes,
  ReduxAction,
} from '../../types';

export default function setBreadcrumb(
  newBreadcrumb: BreadcrumbState
): BreadcrumbActionTypes {
  return {
    type: ReduxAction.SET_BREADCRUMB,
    payload: newBreadcrumb,
  };
}
