import React from 'react';
import './index.scss';
import { RootStateOrAny, useSelector } from 'react-redux';
import ProductBox from '../../components/ProductBox';

function ProductList({ productIds }): JSX.Element {
  const allProducts = useSelector(
    (state: RootStateOrAny) => state.productsReducer.products
  );

  const productsRaw = productIds?.map((element) => {
    return allProducts.find((item) => {
      return item.id === element;
    });
  });

  const products = productsRaw?.filter((value) => {
    return value !== undefined;
  });

  return (
    <div className="product-list">
      {products?.map((productElement) => {
        return (
          <ProductBox
            key={`product-${productElement?.id}`}
            product={productElement}
          />
        );
      })}
    </div>
  );
}

export default ProductList;
