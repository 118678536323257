import React, { useEffect, useState } from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import ArrowLockStick from '../../../../services/ArrowLockStick';
import './index.scss';

function ArrowLock({ secret, setNextStep }): JSX.Element {
  const [result, setResult] = useState<string[]>([]);
  const [newResult, setNewResult] = useState<string>('c');
  let code = secret.toLowerCase();
  code = code.split(',');

  function resetResult() {
    setResult([]);
  }

  useEffect(() => {
    function handleResult() {
      setResult([...result, newResult]);
    }

    if (newResult !== 'c') {
      handleResult();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newResult]);

  useEffect(() => {
    function compareCode(): boolean {
      let i = code.length;

      if (i !== result.length) return false;

      while (i) {
        i -= 1;
        if (code[i] !== result[i]) return false;
      }
      return true;
    }

    setNewResult('c');

    if (compareCode()) {
      setNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <div className="arrow-lock">
      <ArrowLockStick size={200} callback={setNewResult} />
      <div className="arrow-lock__code">
        {result.map((arrowDirection, index) => {
          return (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={`arrow-lock__${index}`}
              className={`arrow-lock__icon arrow-lock__icon--${arrowDirection}`}
            >
              <MdKeyboardBackspace className="arrow-lock__arrow" />
            </span>
          );
        })}
      </div>
      <div
        className="arrow-lock__reset button button--secondary"
        role="button"
        tabIndex={0}
        onClick={resetResult}
        onKeyUp={resetResult}
      >
        Zurücksetzen
      </div>
    </div>
  );
}

export default ArrowLock;
