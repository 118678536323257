import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

function BreadcrumbItem({ text, linkPath, state }): JSX.Element {
  return (
    <div className="breadcrumb-item">
      <Link to={{ pathname: linkPath, state }}>{text}</Link>
    </div>
  );
}

export default BreadcrumbItem;
