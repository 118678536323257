import React, { useEffect, useState } from 'react';
import './index.scss';
import getGPSLocation from '../../services/getGPSLocation';

function GPSTest() {
  let locationTimerID: NodeJS.Timeout | undefined;

  const [gpsLocation, setGpsLocation] = useState({ latitude: 0, longitude: 0 });

  function callLocation() {
    getGPSLocation().then((r) => {
      const location = {
        latitude: r.latitude,
        longitude: r.longitude,
      };

      setGpsLocation(location);
    });
  }

  function callClear() {
    if (locationTimerID) {
      clearInterval(locationTimerID);
    }
  }

  function callInterval() {
    locationTimerID = setInterval(() => callLocation(), 2000);
  }

  useEffect(() => {
    callLocation();
    callInterval();
    return () => {
      callClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gps-test">
      <div className="gps-test__content">
        <div className="gps-test__headline">GPS Test</div>
        <div>Beide Werte müssen {'>'} 0 sein</div>
        <br />
        <div>Latitude: {gpsLocation.latitude}</div>
        <div>Longitude: {gpsLocation.longitude}</div>
      </div>

      <div className="gps-test__content">
        <div className="gps-test__headline">
          Hilfestellung für GPS Aktivierung
        </div>
        <a
          href="https://support.apple.com/de-de/HT207092#:~:text=Dies%20funktioniert%20folgenderma%C3%9Fen%3A,die%20Funktion%20%22Genauer%20Standort%22."
          target="_blank"
          rel="noreferrer"
          className="gps-test__link"
        >
          IOS: Zur Hilfestellung
        </a>

        <a
          href="https://www.samsung.com/de/support/mobile-devices/hinweise-zu-gps-problemen/"
          target="_blank"
          rel="noreferrer"
          className="gps-test__link"
        >
          Android: Zur Hilfestellung
        </a>
      </div>
    </div>
  );
}

export default GPSTest;
