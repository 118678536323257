import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import Message from '../../Message';
import './index.scss';

interface ItemType {
  id: number;
  name: string;
}

function SortingList({ secret, setNextStep, sortingList }): JSX.Element {
  const entries: ItemType[] = [];
  sortingList.forEach((element, index) => {
    entries.push({ id: index + 1, name: element });
  });

  const [sortingEntries, setSortingEntries] = useState<ItemType[]>(entries);

  const codeArray = secret.split(',');

  useEffect(() => {
    const sortingCode: string[] = [];
    sortingEntries.forEach((element) => {
      sortingCode.push(String(element.id));
    });

    function compareCode(): boolean {
      let i = sortingCode.length;

      while (i) {
        i -= 1;
        if (sortingCode[i] !== codeArray[i]) return false;
      }
      return true;
    }

    if (compareCode()) {
      setNextStep();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingEntries]);

  return (
    <div className="sorting-list">
      <Message variant="user">
        <div className="sorting-list__list">
          <ReactSortable list={sortingEntries} setList={setSortingEntries}>
            {sortingEntries.map((item) => (
              <div key={item.id} className="sorting-list__entry">
                {item.name}
              </div>
            ))}
          </ReactSortable>
        </div>
      </Message>
    </div>
  );
}

export default SortingList;
