import { TraceState, TraceActionTypes, ReduxAction } from '../../types';

const initialState: TraceState = {
  traceCollection: {
    id: 0,
    title: '',
    seoTitle: '',
    description: '',
    length: 0,
    time: 0,
    complex: 0,
    traceStepsCollection: [
      {
        banner: '',
        title: '',
        description: [''],
        latitude: 0,
        longitude: 0,
        maxDifference: 999999,
        secretType: '',
        secret: '',
      },
    ],
  },
  contentCollection: {
    items: [
      {
        id: 0,
        order: 0,
        contentItemsCollection: {
          items: [
            {
              richText: {
                json: {},
              },
            },
          ],
        },
      },
    ],
  },
  helpCollection: {
    items: [
      {
        id: 0,
        order: 0,
        helpItemsCollection: {
          items: [
            {
              richText: {
                json: {},
              },
            },
          ],
        },
      },
    ],
  },
};

type State = typeof initialState;

export default function TraceReducer(
  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  state = initialState,
  action: TraceActionTypes
): State {
  switch (action.type) {
    case ReduxAction.SET_TRACE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ReduxAction.SET_CONTENT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ReduxAction.SET_HELP: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
