import React, { useState } from 'react';
import './index.scss';
import { MdClose } from 'react-icons/md';

function Modal({ children }): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);

    document.body.style.overflow = 'hidden';
  }

  function closeModal(event) {
    event.stopPropagation();
    setIsOpen(false);

    document.body.style.overflow = 'auto';
  }

  return (
    <div
      className={`modal${isOpen ? ' modal--active' : ''}`}
      onClick={openModal}
      onKeyDown={openModal}
      role="button"
      tabIndex={0}
    >
      {isOpen ? (
        <div className="modal__container">
          <div
            className="modal__close"
            onClick={closeModal}
            onKeyDown={closeModal}
            role="button"
            tabIndex={0}
          >
            <MdClose className="modal__close-icon" />
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export default Modal;
