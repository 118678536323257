import React, { useEffect } from 'react';
import getDistance from '../../../../services/getDistance';

function FindPoint({ gpsLocation, setNextStep, callDistance }): JSX.Element {
  const distanceTimerLow = 1000;
  const distanceTimerMid = 10000;
  const distanceTimerHigh = 30000;

  let distanceTimer = distanceTimerMid;
  let distance = 999;

  let distanceTimerID: NodeJS.Timeout | undefined;

  function setDistance(value) {
    distance = value;
  }

  getDistance(gpsLocation, setDistance);

  function callClear() {
    if (distanceTimerID) {
      clearInterval(distanceTimerID);
    }
  }

  function handleDistance() {
    getDistance(gpsLocation, setDistance);
    callDistance(distance);

    if (distanceTimerID) {
      switch (true) {
        case distance <= 0.015: {
          clearInterval(distanceTimerID);
          setNextStep();
          break;
        }
        case distance <= 0.05: {
          if (distanceTimer !== distanceTimerLow) {
            distanceTimer = distanceTimerLow;
            callClear();
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            callInterval(distanceTimerLow);
          }
          break;
        }
        case distance <= 0.1: {
          if (distanceTimer !== distanceTimerMid) {
            distanceTimer = distanceTimerMid;
            callClear();
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            callInterval(distanceTimerMid);
          }
          break;
        }
        case distance > 0.1: {
          if (distanceTimer !== distanceTimerHigh) {
            distanceTimer = distanceTimerHigh;
            callClear();
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            callInterval(distanceTimerHigh);
          }
          break;
        }
        default: {
          distanceTimer = distanceTimerMid;
        }
      }
    }
  }

  function callInterval(timer) {
    distanceTimerID = setInterval(() => handleDistance(), timer);
  }

  useEffect(() => {
    callInterval(distanceTimerMid);
    return () => {
      callClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default FindPoint;
