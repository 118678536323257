import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setHelp } from '../redux/actions/traceAction';
import traceService from '../services/TraceService';

function GetHelp(contentId: number): void {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTrace = async () => {
      return traceService.getHelp(contentId);
    };
    fetchTrace().then((value) => {
      dispatch(setHelp(value?.helpCollection));
    });
  }, [contentId, dispatch]);
}

export default GetHelp;
