import { gql } from 'graphql-tag';

export const GET_CONTENT = gql`
  query GetContent($id: String!) {
    contentCollection(where: { traceId: $id }, limit: 20, order: order_ASC) {
      items {
        id
        order
        contentItemsCollection(limit: 10) {
          items {
            id
            richText {
              json
              links {
                assets {
                  block {
                    sys {
                      id
                    }
                    url
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TRACE = gql`
  query GetTrace($id: Int!) {
    traceCollection(where: { id: $id }, limit: 1) {
      items {
        id
        title
        seoTitle
        traceStepsCollection(limit: 30) {
          items {
            title
            banner {
              title
              url
            }
            location {
              lat
              lon
            }
            maxDifference
            secretType
            secret
            sortingList
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProducts {
    productCollection {
      items {
        id
        categoryId
        length
        time
        complex
        price
        pseudoPrice
        title
        seoTitle
        description
        descriptionLong {
          json
        }
        imagesCollection {
          items {
            fileName
            url
          }
        }
        imagePreview {
          fileName
          url
        }
      }
    }
  }
`;

export const GET_HELP = gql`
  query getHelp($id: String!) {
    helpCollection(where: { traceId: $id }, limit: 15, order: order_ASC) {
      items {
        id
        order
        helpItemsCollection {
          items {
            id
            richText {
              json
            }
          }
        }
      }
    }
  }
`;
