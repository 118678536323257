import { LocationState } from 'history';

export default function checkLocationUndefined(
  location: LocationState,
  products
): number {
  if (location.state) {
    return location.state.id;
  }

  const url = location.pathname.split('/');
  if (products.length < 1) {
    return 0;
  }

  const product = products?.find((element) => {
    return element.seoTitle === url[2];
  });

  if (product === undefined) {
    return 0;
  }

  return product.id;
}
