import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import './index.scss';
import ProductList from '../../containers/ProductList';
import Breadcrumb from '../../containers/Breadcrumb';
import setBreadcrumb from '../../redux/actions/breadcrumbAction';

function Category({ location }): JSX.Element {
  const categories = useSelector(
    (state: RootStateOrAny) => state.categoriesReducer.categories
  );
  const productsByCategory = useSelector(
    (state: RootStateOrAny) => state.productsReducer.productsByCategory
  );
  const catId = location.state.id;
  const category = categories?.find((categoryElement) => {
    return categoryElement.id === catId;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadcrumb({ id: catId }));
  });

  return (
    <div className="category">
      <div className="category__banner">
        <img
          className="category__banner-image"
          src={`${process.env.PUBLIC_URL}/images/banner/${category.imageBanner}`}
          alt={category.title}
        />
      </div>
      <Breadcrumb className="breadcrumb--small" />
      <ProductList productIds={productsByCategory[catId]} />
    </div>
  );
}

export default Category;
