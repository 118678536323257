import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

function ProductBox({ product }): JSX.Element {
  return (
    <div className="product-box">
      <Link
        to={{
          pathname: `/detail/${product?.seoTitle}`,
          state: { id: product?.id },
        }}
      >
        <div className="product-box__image-wrapper">
          <img
            className="product-box__image"
            src={`${product?.imagePreview?.url}`}
            alt={product?.title}
          />
        </div>
        <div className="product-box__content">
          <div className="product-box__title">{product?.title}</div>
          <div className="product-box__desc">{product?.description}</div>
        </div>
      </Link>
    </div>
  );
}

export default ProductBox;
