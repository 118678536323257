import React from 'react';
import PasswordText from './PasswordText';
import CombinationLock from './CombinationLock';
import FindPoint from './FindPoint';
import ArrowLock from './ArrowLock';
import SortingList from './SortingList';
import { Secret } from '../../../types';
import './index.scss';

function CodeCheck({
  secretType,
  secret,
  setNextStep,
  gpsLocation,
  sortingList,
  callDistance,
}): JSX.Element {
  function handleType() {
    switch (secretType) {
      case Secret.findPoint:
        return gpsLocation.lat !== undefined ? (
          <FindPoint
            gpsLocation={gpsLocation}
            setNextStep={setNextStep}
            callDistance={callDistance}
          />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        );
      case Secret.text:
        return <PasswordText secret={secret} setNextStep={setNextStep} />;
      case Secret.combinationLock:
        return <CombinationLock secret={secret} setNextStep={setNextStep} />;
      case Secret.arrowLock:
        return <ArrowLock secret={secret} setNextStep={setNextStep} />;
      case Secret.sortingList:
        return (
          <SortingList
            secret={secret}
            setNextStep={setNextStep}
            sortingList={sortingList}
          />
        );
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  }

  return handleType();
}

export default CodeCheck;
