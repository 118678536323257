import apolloClient from '../graphql';
import { GET_PRODUCT } from './queries';

class ProductService {
  private response;

  async getProducts(): Promise<any> {
    // eslint-disable-next-line no-useless-catch
    try {
      this.response = await apolloClient.query({
        query: GET_PRODUCT,
      });

      if (!this.response || !this.response.data) {
        throw Error('No data from products');
      }

      return this.response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new ProductService();
