import { ReduxAction } from '../../types';

export function setTrace(traceObject) {
  const traceCollection = { traceCollection: traceObject };
  return {
    type: ReduxAction.SET_TRACE,
    payload: traceCollection,
  };
}

export function setContent(contentObject) {
  const contentCollection = { contentCollection: contentObject };
  return {
    type: ReduxAction.SET_CONTENT,
    payload: contentCollection,
  };
}

export function setHelp(contentObject) {
  const helpCollection = { helpCollection: contentObject };
  return {
    type: ReduxAction.SET_HELP,
    payload: helpCollection,
  };
}
