import React from 'react';
import './index.scss';

function LegalNotice(): JSX.Element {
  return (
    <div className="legal-notice">
      <div className="legal-notice__content">
        <div className="legal-notice__title">Anschrift</div>
        <div className="legal-notice__text">
          Marius von Hassel <br />
          Freiheitsweg 58 <br />
          13407 Berlin <br />
        </div>
        <div className="legal-notice__title">Email Adresse</div>
        <div className="legal-notice__text">mariusvonhassel@web.de</div>
        <div className="legal-notice__title">Telefonummer</div>
        <div className="legal-notice__text">01712688203</div>
      </div>
    </div>
  );
}

export default LegalNotice;
