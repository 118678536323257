import { combineReducers, Reducer } from 'redux';
// import { i18nState } from 'redux-i18n';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import toggleReducer from './toggleHandlerReducer';
// import pageTypeReducer from './pageTypeReducer';
import productsReducer from './productsReducer';
import traceReducer from './traceReducer';
import categoriesReducer from './categoriesReducer';
import notificationReducer from './notificationReducer';
import breadcrumbReducer from './breadcrumbReducer';

export default (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    breadcrumbReducer,
    notificationReducer,
    productsReducer,
    traceReducer,
    toggleReducer,
    // pageTypeReducer,
    categoriesReducer,
    // i18nState,
  });
