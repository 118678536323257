import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { MdTimeline, MdWatchLater, MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';
import './index.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Currency } from '@chaitanyapotti/react-intl-number-format';
import Breadcrumb from '../../containers/Breadcrumb';
import Button from '../../components/Button/intex';
import setBreadcrumb from '../../redux/actions/breadcrumbAction';
import checkLocationUndefined from '../../services/checkLocationUndefined';

function Detail({ location }): JSX.Element {
  const wrapAround = true;
  const defaultControlsConfig = {
    nextButtonClassName: 'is--hidden',
    prevButtonClassName: 'is--hidden',
    pagingDotsContainerClassName: 'slider-control-bottom-container',
    pagingDotsClassName: 'slider-control-dot',
  };

  const products = useSelector(
    (state: RootStateOrAny) => state.productsReducer.products
  );

  const productId = checkLocationUndefined(location, products);
  const product = products?.find((element) => {
    return element.id === productId;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (product?.categoryId !== undefined) {
      dispatch(setBreadcrumb({ id: product?.categoryId }));
    }
  });

  return (
    <div className="detail">
      <Breadcrumb />
      <Carousel
        wrapAround={wrapAround}
        defaultControlsConfig={defaultControlsConfig}
      >
        {product?.imagesCollection?.items.map((imageElement) => {
          return (
            <img
              src={`${imageElement.url}`}
              alt="test"
              key={imageElement.url}
            />
          );
        })}
      </Carousel>

      <div className="detail__content">
        <h1 className="detail__headliner">{product?.title}</h1>
        <div className="detail__text">
          <div className="detail__description">
            {documentToReactComponents(product?.descriptionLong?.json)}
          </div>
          <div className="detail__property">
            <div className="detail__property-left">
              <div className="detail__property-icon-wrap detail__property-icon-wrap--settings">
                <MdSettings className="detail__property-icon" />
              </div>
              <div className="detail__property-text">Schwierigkeitsgrad:</div>
            </div>
            <div className="detail__property-right">
              <img
                className="detail__property-image"
                src={`${process.env.PUBLIC_URL}/images/icons/difficulty${product?.complex}.svg`}
                alt={product?.title}
              />
            </div>
          </div>

          <div className="detail__property">
            <div className="detail__property-left">
              <div className="detail__property-icon-wrap">
                <MdTimeline className="detail__property-icon" />
              </div>
              <div className="detail__property-text">Länge:</div>
            </div>
            <div className="detail__property-right">{product?.length}km</div>
          </div>

          <div className="detail__property">
            <div className="detail__property-left">
              <div className="detail__property-icon-wrap">
                <MdWatchLater className="detail__property-icon" />
              </div>
              <div className="detail__property-text">Dauer:</div>
            </div>
            <div className="detail__property-right">
              ca. {product?.length} Stunden
            </div>
          </div>

          <div className="detail__price-wrapper">
            {product?.pseudoPrice > product?.price ? (
              <div className="detail__pseudo-price">
                <Currency>{product?.pseudoPrice}</Currency>*
              </div>
            ) : (
              ''
            )}
            <div className="detail__price">
              <Currency>{product?.price}</Currency>*
            </div>
          </div>
          <div className="detail__vat">* inkl. MwSt.</div>
          <Link
            to={{
              pathname: `/route/${product?.seoTitle}`,
              state: { id: product?.id },
            }}
          >
            <Button className="button--submit button--fullscreen">
              Route starten
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Detail;
