import { ProductsState, ProductsActionTypes, ReduxAction } from '../../types';

const initialState: ProductsState = {
  products: [],
  productsByCategory: [],
};

type State = typeof initialState;

export default function productsReducer(
  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  state = initialState,
  action: ProductsActionTypes
): State {
  switch (action.type) {
    case ReduxAction.GET_PRODUCTS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ReduxAction.GET_PRODUCTS_BY_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
