import {
  BreadcrumbActionTypes,
  BreadcrumbState,
  ReduxAction,
} from '../../types';

const initialState: BreadcrumbState = {
  id: 0,
};

type State = typeof initialState;

export default function breadcrumbReducer(
  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  state = initialState,
  action: BreadcrumbActionTypes
): State {
  switch (action.type) {
    case ReduxAction.SET_BREADCRUMB: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
