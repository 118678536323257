import React from 'react';
import Header from '../Header';
import Router from '../Router';
import './index.scss';
import Notification from '../Notification';

function PageWrap(): JSX.Element {
  return (
    <div className="page">
      <Header />

      <div className="page__wrap">
        <Router />
      </div>
      <Notification />
    </div>
  );
}

export default PageWrap;
