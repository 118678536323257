import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getProducts,
  getProductsByCategory,
} from '../redux/actions/productsAction';
import productService from '../services/ProductService';

function GetProducts(): void {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      return productService.getProducts();
    };

    fetchProducts().then((value) => {
      dispatch(getProducts(value.productCollection.items));
    });
    dispatch(getProductsByCategory());
  }, [dispatch]);
}

export default GetProducts;
