import React from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';
import CanvasMenu from '../CanvasMenu';
import setCanvasMenu from '../../redux/actions/toggleHandlerAction';
import './index.scss';

function Header(): JSX.Element {
  const canvasState = useSelector(
    (state: RootStateOrAny) => state.toggleReducer.canvasState
  );
  const dispatch = useDispatch();

  function handleToggle(): void {
    dispatch(setCanvasMenu({ canvasState: !canvasState }));

    document.body.style.overflow = 'hidden';
  }

  return (
    <header className="header">
      <div className="header__container">
        <div
          className="header__menu"
          onClick={handleToggle}
          aria-hidden
          onKeyDown={handleToggle}
          role="button"
          tabIndex={0}
        >
          <MdMenu className="header__menu-icon" />
        </div>
        <CanvasMenu />
        <Link to="/">
          <img
            className="header__logo"
            src={`${process.env.PUBLIC_URL}/images/icons/x-cases.svg`}
            title="logo"
            alt="logo"
          />
        </Link>
      </div>
    </header>
  );
}

export default Header;
