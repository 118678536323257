import { getDistanceBetween } from 'geolocation-distance-between';
import getGPSLocation from './getGPSLocation';

export default function getDistance(
  gpsLocation,
  setDistance: (distance: number) => void
): void {
  let distanceBetween = 0;

  const destination = {
    latitude: gpsLocation.lat,
    longitude: gpsLocation.lon,
  };

  getGPSLocation().then((r) => {
    const location = {
      latitude: r.latitude,
      longitude: r.longitude,
    };

    distanceBetween = getDistanceBetween(location, destination);
    setDistance(distanceBetween);
  });
}
