export enum ReduxAction {
  SET_CANVAS = 'SET_CANVAS',
  GET_PRODUCTS = 'GET_PRODUCTS',
  SET_TRACE = 'SET_TRACE',
  SET_CONTENT = 'SET_CONTENT',
  SET_HELP = 'SET_HELP',
  GET_CATEGORIES = 'GET_CATEGORIES',
  SET_BREADCRUMB = 'SET_BREADCRUMB',
  GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

export enum Secret {
  finish = 'finish',
  text = 'text',
  findPoint = 'findPoint',
  combinationLock = 'combinationLock',
  arrowLock = 'arrowLock',
  sortingList = 'sortingList',
}

interface ToggleCanvas {
  type: string;
  payload: CanvasState;
}

export interface CanvasState {
  canvasState: boolean;
}

interface Categories {
  type: string;
  payload: CategoryState;
}

export interface BreadcrumbState {
  id: number;
}

interface Breadcrumb {
  type: string;
  payload: BreadcrumbState;
}

interface Notification {
  type: string;
  payload: CreateNote;
}

export interface CreateNote {
  type: string;
  text: string;
  id: number;
}

export interface RemoveNote {
  type: string;
  payload: number;
}

export interface NotificationNote {
  type: string;
  text: string;
}

export interface CategoryState {
  categories: CategoryTree[];
}

export interface CategoryTree {
  id: number;
  title: string;
  seoTitle: string;
  image: string;
  imageBanner: string;
  parent: CategoryTree | number;
}

export interface Product {
  id: number;
  categoryId: number;
  images: string[];
  imagePreview: string;
  title: string;
  seoTitle: string;
  description: string;
  descriptionLong: string;
  length: number;
  time: number;
  complex: number;
  price: number;
  pseudoPrice: number;
}

export interface ProductsState {
  products: Product[];
  productsByCategory: number[][];
}

export interface ProductsByCategoryState {
  productsByCategory: number[][];
}

export interface ProductsByCategory {
  type: string;
  payload: ProductsByCategoryState;
}

interface Products {
  type: string;
  payload: ProductsState;
}

export interface ProductContent {
  items: [
    {
      id: number;
      order: number;
      contentItemsCollection: ContentItems;
    }
  ];
}

interface ContentItems {
  items: [
    {
      richText: {
        json: any;
      };
    }
  ];
}

interface ProductHelp {
  items: [
    {
      id: number;
      order: number;
      helpItemsCollection: HelpItems;
    }
  ];
}

export interface HelpItems {
  items: [
    {
      richText: {
        json: any;
      };
    }
  ];
}

export interface TraceStep {
  banner: string;
  title: string;
  description: string[];
  latitude: number;
  longitude: number;
  maxDifference: number;
  secretType: string;
  secret: string;
}

export interface TraceState {
  traceCollection: TraceObject;
  contentCollection: ProductContent;
  helpCollection: ProductHelp;
}

export interface TraceObject {
  id: number;
  title: string;
  seoTitle: string;
  description: string;
  length: number;
  time: number;
  complex: number;
  traceStepsCollection: TraceStep[];
}

interface Trace {
  type: string;
  payload: TraceState;
}

export type BreadcrumbActionTypes = Breadcrumb;
export type CanvasActionTypes = ToggleCanvas;
export type CategoriesActionTypes = Categories;
export type ProductsActionTypes = Products;
export type ProductsByCategoryTypes = ProductsByCategory;
export type TraceActionTypes = Trace;
export type NotificationActionTypes = Notification;
