import React from 'react';
import './index.scss';

function TraceFooter({ onClickHelp, helpCount }): JSX.Element {
  return (
    <div className="trace-footer">
      <div
        className="trace-footer__element"
        onClick={onClickHelp}
        onKeyDown={onClickHelp}
        role="button"
        tabIndex={0}
      >
        <div className="trace-footer__image-container">
          <img
            className="trace-footer__image"
            src={`${process.env.PUBLIC_URL}/images/icons/helpDetective.svg`}
            alt="help"
          />
          <div className="trace-footer__count">{helpCount}</div>
        </div>
      </div>
    </div>
  );
}

export default TraceFooter;
