import React from 'react';
import './index.scss';
import ProductList from '../../containers/ProductList';
import CategoryList from '../../containers/CategoryList';

function Home(): JSX.Element {
  return (
    <div className="home">
      <ProductList productIds={[1, 2]} />
      <CategoryList />
    </div>
  );
}

export default Home;
