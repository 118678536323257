import { CanvasState, ReduxAction, CanvasActionTypes } from '../../types';

export default function setCanvasMenu(
  toggleCanvas: CanvasState
): CanvasActionTypes {
  return {
    type: ReduxAction.SET_CANVAS,
    payload: toggleCanvas,
  };
}
