import React, { useEffect, useState } from 'react';
import './index.scss';

function CombinationLock({ secret, setNextStep }): JSX.Element {
  const [codeInput, setCodeInput] = useState<string[]>([]);
  const codeArray = secret.split(',');

  function handleValue(
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ): void {
    const newCode = event.target.value;
    const newCodeInput: string[] = [];
    codeInput.forEach((element) => {
      newCodeInput.push(element);
    });
    newCodeInput[index] = newCode;

    setCodeInput(newCodeInput);
  }

  function checkCombination() {
    return codeInput.toString() === secret;
  }

  function resetComponent() {
    setCodeInput([]);
  }

  useEffect(() => {
    if (checkCombination()) {
      resetComponent();
      setNextStep();
    }
  });

  return (
    <div className="combination-lock">
      <span className="combination-lock__nails" />
      <span className="combination-lock__nails" />
      <span className="combination-lock__nails" />
      <span className="combination-lock__nails" />
      <div className="combination-lock__wrapper">
        {codeArray.map((key, index) => {
          return (
            <div className="combination-lock__dig">
              <select
                className="combination-lock__dig-input"
                onChange={(event) => handleValue(event, index)}
              >
                <option>-</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
              </select>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CombinationLock;
