import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const defaultProps = {
  answer: false,
  headline: false,
  variant: PropTypes.oneOf(['user', 'answer']),
};

function Message({ variant, children, headline }): JSX.Element {
  return (
    <div
      className={`message${variant ? ` message--${variant}` : ''}${
        headline ? ' message--headline' : ''
      }`}
    >
      {children}
    </div>
  );
}

Message.defaultProps = defaultProps;

export default Message;
