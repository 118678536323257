import React from 'react';
import './App.scss';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from '@chaitanyapotti/react-intl-number-format';
import 'normalize.css';
import PageWrap from './containers/Page';
import GetProducts from './ApiHandler/GetProducts';

const intlConfig = {
  locale: 'de-DE',
  options: {
    currency: 'EUR',
    maximumFractionDigits: 2,
  },
};

function App({ history }: { history: History }): JSX.Element {
  GetProducts();

  return (
    <IntlProvider config={intlConfig}>
      <ConnectedRouter history={history}>
        <PageWrap />
      </ConnectedRouter>
    </IntlProvider>
  );
}

export default App;
