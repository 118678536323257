import apolloClient from '../graphql/index';
import { GET_TRACE, GET_CONTENT, GET_HELP } from './queries';

class TraceService {
  private traceResponse;

  private contentResponse;

  private helpResponse;

  async getTrace(traceId: number): Promise<any> {
    if (traceId === 0) {
      return null;
    }

    // eslint-disable-next-line no-useless-catch
    try {
      this.traceResponse = await apolloClient.query({
        query: GET_TRACE,
        variables: { id: traceId },
      });

      if (!this.traceResponse || !this.traceResponse.data) {
        throw Error('No data form trace');
      }

      return this.traceResponse.data;
    } catch (err) {
      throw err;
    }
  }

  async getContent(contentId: number): Promise<any> {
    if (contentId === 0) {
      return null;
    }

    const contentIdString = String(contentId);

    // eslint-disable-next-line no-useless-catch
    try {
      this.contentResponse = await apolloClient.query({
        query: GET_CONTENT,
        variables: { id: contentIdString },
      });

      if (!this.contentResponse || !this.contentResponse.data) {
        throw Error('No data form trace');
      }

      return this.contentResponse.data;
    } catch (err) {
      throw err;
    }
  }

  async getHelp(helpId: number): Promise<any> {
    if (helpId === 0) {
      return null;
    }

    const helpIdString = String(helpId);

    // eslint-disable-next-line no-useless-catch
    try {
      this.helpResponse = await apolloClient.query({
        query: GET_HELP,
        variables: { id: helpIdString },
      });

      if (!this.helpResponse || !this.helpResponse.data) {
        throw Error('No data form trace');
      }

      return this.helpResponse.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new TraceService();
