import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setContent } from '../redux/actions/traceAction';
import traceService from '../services/TraceService';

function GetContent(contentId: number): void {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTrace = async () => {
      return traceService.getContent(contentId);
    };
    fetchTrace().then((value) => {
      dispatch(setContent(value?.contentCollection));
    });
  }, [contentId, dispatch]);
}

export default GetContent;
