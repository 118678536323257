import { ReduxAction, NotificationActionTypes, CreateNote } from '../../types';

const initialState = [];

type State = typeof initialState;

export default function NotificationReducer(
  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  state: State | CreateNote[] = initialState,
  action: NotificationActionTypes | any
): State | CreateNote[] {
  switch (action.type) {
    case ReduxAction.SET_NOTIFICATION: {
      return state.length > 0 ? [action.payload, ...state] : [action.payload];
    }
    case ReduxAction.REMOVE_NOTIFICATION: {
      return state.filter((note: CreateNote) => note.id !== action.payload);
    }
    default:
      return {
        ...state,
      };
  }
}
