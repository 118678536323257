import React, { useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  MdClose,
  MdLinearScale,
  MdHome,
  MdContactPage,
  MdPolicy,
  MdGpsFixed,
} from 'react-icons/md';
import './index.scss';
import setCanvasMenu from '../../redux/actions/toggleHandlerAction';

function CanvasMenu(): JSX.Element {
  const canvasState = useSelector(
    (state: RootStateOrAny) => state.toggleReducer.canvasState
  );

  const categories = useSelector(
    (state: RootStateOrAny) => state.categoriesReducer.categories
  );

  const dispatch = useDispatch();

  const [isSubCategoryShown, setIsSubCategoryShown] = useState(false);

  function toggleCategory(): void {
    setIsSubCategoryShown(!isSubCategoryShown);
  }

  function closeCanvas(): void {
    dispatch(setCanvasMenu({ canvasState: false }));

    document.body.style.overflow = 'unset';
  }

  function handleCloser(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void | undefined {
    const targetValue = event.target as HTMLButtonElement;

    if (targetValue.className === 'canvas-menu canvas-menu--active') {
      closeCanvas();
    }
  }

  return (
    <div
      className={`canvas-menu${canvasState ? ' canvas-menu--active' : ''}`}
      onClick={handleCloser}
      aria-hidden
      role="button"
    >
      <div className="canvas-menu__closer">
        <MdClose className="canvas-menu__close-icon" />
      </div>

      <nav className="canvas-menu__content">
        <div
          className="canvas-menu__link"
          onClick={closeCanvas}
          aria-hidden
          onKeyDown={closeCanvas}
          role="button"
          tabIndex={0}
        >
          <Link to="/">
            <MdHome className="canvas-menu__link-icon" />
            <span className="canvas-menu__link-text">Home</span>
          </Link>
        </div>

        <div
          className="canvas-menu__link canvas-menu__link--route"
          onClick={toggleCategory}
          role="button"
          aria-hidden
        >
          <MdLinearScale className="canvas-menu__link-icon" />
          <span className="canvas-menu__link-text">Routen</span>
        </div>

        <ul
          className={`canvas-menu__nav${
            isSubCategoryShown ? ' canvas-menu__nav--active' : ''
          }`}
        >
          {categories.map((item) => {
            return (
              item.id !== 0 && (
                <Link
                  key={item.id}
                  to={{
                    pathname: `/kategorie/${item.seoTitle}`,
                    state: { id: item.id },
                  }}
                >
                  <li className="canvas-menu__nav-element">
                    <span
                      className="canvas-menu__nav-item"
                      onClick={closeCanvas}
                      aria-hidden
                      onKeyDown={closeCanvas}
                      role="button"
                      tabIndex={0}
                    >
                      {item.title}
                    </span>
                  </li>
                </Link>
              )
            );
          })}
        </ul>

        <div
          className="canvas-menu--link"
          onClick={closeCanvas}
          role="button"
          aria-hidden
        >
          <Link to="/gps-test">
            <MdGpsFixed className="canvas-menu__link-icon" />
            <span className="canvas-menu__link-text">GPS Testseite</span>
          </Link>
        </div>

        <div
          className="canvas-menu--link"
          onClick={closeCanvas}
          role="button"
          aria-hidden
        >
          <Link to="/impressum">
            <MdContactPage className="canvas-menu__link-icon" />
            <span className="canvas-menu__link-text">Impressum</span>
          </Link>
        </div>
        <div
          className="canvas-menu--link"
          onClick={closeCanvas}
          role="button"
          aria-hidden
        >
          <Link to="/datenschutz">
            <MdPolicy className="canvas-menu__link-icon" />
            <span className="canvas-menu__link-text">Datenschutz</span>
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default CanvasMenu;
