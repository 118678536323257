import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './index.scss';
import Message from '../Message';
import renderOptions from '../../../services/renderOptions';

function Help({ helpOutput }): JSX.Element {
  return (
    <div className="help">
      {helpOutput?.map((element) => {
        return (
          <Message variant="answer" key={`help-content-${element.id}`}>
            {documentToReactComponents(
              element?.richText?.json,
              renderOptions(element?.text?.links)
            )}
          </Message>
        );
      })}
    </div>
  );
}

export default Help;
