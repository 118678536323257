import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setTrace } from '../redux/actions/traceAction';
import traceService from '../services/TraceService';

function GetTrace(traceId: number): void {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTrace = async () => {
      return traceService.getTrace(traceId);
    };
    fetchTrace().then((value) => {
      dispatch(setTrace(value?.traceCollection?.items[0]));
    });
  }, [dispatch, traceId]);
}

export default GetTrace;
