import React from 'react';
import './index.scss';
import { RootStateOrAny, useSelector } from 'react-redux';
import CategoryBox from '../../components/CategoryBox';

function CategoryList(): JSX.Element {
  const categories = useSelector(
    (state: RootStateOrAny) => state.categoriesReducer.categories
  );

  return (
    <div className="category-list">
      {categories?.map((category) => {
        return (
          <CategoryBox key={`category-${category.id}`} category={category} />
        );
      })}
    </div>
  );
}

export default CategoryList;
