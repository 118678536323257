import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import Modal from '../containers/Modal';

export default function renderOptions(links) {
  if (links === undefined) {
    return {};
  }

  const assetMap = new Map();

  links.assets.block.forEach((asset) => {
    assetMap.set(asset.sys.id, asset);
  });

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // eslint-disable-next-line react/destructuring-assignment
        const asset = assetMap.get(node.data.target.sys.id);

        return (
          <Modal>
            <img className="modal__image" src={asset.url} alt={asset.title} />
          </Modal>
        );
      },
    },
  };
}
