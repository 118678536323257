import React, { useEffect, useState } from 'react';

function ArrowLockStick({ size, callback }): JSX.Element {
  const canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
  const internalRadius = (size - (size / 2 + 10)) / 2;
  const maxMoveStick = internalRadius + 5;
  const centerX = size / 2;
  const centerY = size / 2;
  const [context, setContext] = useState(canvasRef?.current?.getContext('2d'));
  const [canvas, setCanvas] = useState(canvasRef?.current);

  let lastResult = 'C';
  let pressed = 0;
  let movedX = centerX;
  let movedY = centerY;
  let canvasX = 0;
  let canvasY = 0;

  const imageBackground = new Image();
  imageBackground.src = `${process.env.PUBLIC_URL}/images/icons/ArrowLockBackground.svg`;

  const imageButton = new Image();
  imageButton.src = `${process.env.PUBLIC_URL}/images/icons/ArrowLockButton.svg`;

  function drawBackground() {
    if (context) {
      context.drawImage(imageBackground, 0, 0, size, size);
    }
  }

  function drawStick() {
    if (movedX < internalRadius) {
      movedX = maxMoveStick;
    }

    if (movedX + internalRadius > size) {
      movedX = size - maxMoveStick;
    }

    if (movedY < internalRadius) {
      movedY = maxMoveStick;
    }

    if (movedY + internalRadius > size) {
      movedY = size - maxMoveStick;
    }

    if (context) {
      context.drawImage(
        imageButton,
        movedX - size / 4,
        movedY - size / 4,
        size / 2,
        size / 2
      );
    }
  }

  function clearCanvas() {
    if (context) {
      context.clearRect(0, 0, size, size);
      drawBackground();
      drawStick();
    }
  }

  function checkPosition() {
    const distance = Math.abs(centerX - movedX) + Math.abs(centerY - movedY);

    if (distance <= 20) {
      if (lastResult !== 'c') {
        callback('c');
        lastResult = 'c';
      }
    }

    if (
      distance > 20 &&
      movedX > 60 &&
      movedX < 140 &&
      movedY < 80 &&
      movedY >= 50
    ) {
      if (lastResult !== 'n') {
        callback('n');
        lastResult = 'n';
      }
    }

    if (
      distance > 20 &&
      movedX > 60 &&
      movedX < 140 &&
      movedY > 120 &&
      movedY <= 150
    ) {
      if (lastResult !== 's') {
        callback('s');
        lastResult = 's';
      }
    }

    if (
      distance > 20 &&
      movedX >= 50 &&
      movedX < 80 &&
      movedY > 60 &&
      movedY < 140
    ) {
      if (lastResult !== 'w') {
        callback('w');
        lastResult = 'w';
      }
    }

    if (
      distance > 20 &&
      movedX <= 150 &&
      movedX > 120 &&
      movedY > 60 &&
      movedY < 140
    ) {
      if (lastResult !== 'o') {
        callback('o');
        lastResult = 'o';
      }
    }
  }

  function clickStart(): void {
    if ((canvasY === 0 || canvasX === 0) && canvas) {
      canvasX = canvas.getBoundingClientRect().x;
      canvasY = canvas.getBoundingClientRect().y;
    }

    pressed = 1;
  }

  function touchMove(event: React.TouchEvent<HTMLCanvasElement>): void {
    if (
      pressed === 1 &&
      event.targetTouches[0].target === canvas &&
      canvas.offsetParent
    ) {
      movedX = event.targetTouches[0].pageX - canvasX;
      movedY = event.targetTouches[0].pageY - canvasY - window.scrollY;
      clearCanvas();
    }
  }

  function clickMove(event: React.MouseEvent<HTMLCanvasElement>) {
    if (pressed === 1 && event.target === canvas && canvas.offsetParent) {
      movedX = event.pageX - canvasX;
      movedY = event.pageY - canvasY - window.scrollY;

      clearCanvas();
    }
  }

  function clickEnd(): void {
    pressed = 0;

    checkPosition();
    movedX = centerX;
    movedY = centerY;
    clearCanvas();
  }

  useEffect(() => {
    setContext(canvasRef?.current?.getContext('2d'));
    setCanvas(canvasRef?.current);
  }, [canvasRef]);

  useEffect(() => {
    imageBackground.onload = () => {
      setTimeout(() => {
        drawBackground();
      }, 400);
    };
    imageButton.onload = () => {
      setTimeout(() => {
        drawStick();
      }, 500);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return (
    <canvas
      className="arrow-lock__canvas"
      width={size}
      height={size}
      ref={canvasRef}
      onTouchStart={clickStart}
      onTouchMove={touchMove}
      onTouchEnd={clickEnd}
      onMouseDown={clickStart}
      onMouseMove={clickMove}
      onMouseUp={clickEnd}
    />
  );
}

export default ArrowLockStick;
