import { ProductsByCategoryTypes, ReduxAction } from '../../types';

export function getProducts(productsObject): {
  payload: { products: any };
  type: ReduxAction.GET_PRODUCTS;
} {
  const products = { products: productsObject };

  return {
    type: ReduxAction.GET_PRODUCTS,
    payload: products,
  };
}

export function getProductsByCategory(): ProductsByCategoryTypes {
  const productsByCategory = {
    productsByCategory: [[], [1, 2], [2], [1], [], [], []],
  };

  return {
    type: ReduxAction.GET_PRODUCTS_BY_CATEGORY,
    payload: productsByCategory,
  };
}
