import React from 'react';
import './index.scss';
import { RootStateOrAny, useSelector } from 'react-redux';
import BreadcrumbItem from '../../components/BreadcrumbItem';

function Breadcrumb({ className = '' }): JSX.Element {
  const breadcrumb = useSelector(
    (state: RootStateOrAny) => state.breadcrumbReducer.id
  );

  const categories = useSelector(
    (state: RootStateOrAny) => state.categoriesReducer.categories
  );

  const category = categories.find((categoryElement) => {
    return categoryElement.id === breadcrumb;
  });

  return (
    <div className={`breadcrumb ${className}`}>
      <BreadcrumbItem text="Home" linkPath="/" state={{ id: 0 }} />
      {breadcrumb?.id !== 0 && (
        <span className="breadcrumb__elements">
          <div className="breadcrumb__arrow">
            <img
              className="breadcrumb__arrow-image"
              src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`}
              alt="arrow-right"
            />
          </div>
          <BreadcrumbItem
            text={category?.title}
            linkPath={category?.seoTitle}
            state={category?.id}
          />
        </span>
      )}
    </div>
  );
}

export default Breadcrumb;
