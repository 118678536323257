import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Note from './Note';
import './index.scss';

function Notification() {
  const notification = useSelector(
    (state: RootStateOrAny) => state.notificationReducer
  );

  return (
    <div className="notification">
      {notification?.length > 0 &&
        notification.map((element) => {
          return <Note note={element} />;
        })}
    </div>
  );
}

export default Notification;
