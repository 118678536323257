import React from 'react';
import './index.scss';
import { MdCheck, MdOutlineWarningAmber } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../../redux/actions/notificationAction';

function Note({ note }) {
  const dispatch = useDispatch();

  function timeoutHandler() {
    dispatch(removeNotification(note.id));
  }
  setTimeout(() => timeoutHandler(), 5000);

  function iconHandler(icon) {
    switch (icon.type) {
      case 'alert':
        return <MdOutlineWarningAmber className="note__icon" />;
      default:
        return <MdCheck className="note__icon" />;
    }
  }

  return (
    <div
      className={`note note--${note.type}`}
      key={note.id}
      onClick={timeoutHandler}
      onKeyUp={timeoutHandler}
      role="button"
      tabIndex={0}
    >
      <div className="note__text">{note.text}</div>
      {iconHandler(note)}
    </div>
  );
}

export default Note;
