import { CategoryState, CategoriesActionTypes, ReduxAction } from '../../types';

const initialState: CategoryState = {
  categories: [
    {
      id: 1,
      title: 'Sehenswürdigkeiten',
      seoTitle: 'sehenswuerdigkeiten',
      image: 'signs200x200.jpg',
      imageBanner: 'signs390x150.jpg',
      parent: 0,
    },
    {
      id: 2,
      title: 'Natur & Parks',
      seoTitle: 'natur-parks',
      image: 'park200x200.jpg',
      imageBanner: 'parks390x150.jpg',
      parent: 0,
    },
    {
      id: 3,
      title: 'Geschichte & Kultur',
      seoTitle: 'geschichte-kultur',
      image: 'culture200x200.jpg',
      imageBanner: 'culture390x150.jpg',
      parent: 0,
    },
    {
      id: 4,
      title: 'Moderne Gebäude',
      seoTitle: 'moderne-gebaeude',
      image: 'modern200x200.jpg',
      imageBanner: 'architecture390x150.jpg',
      parent: 0,
    },
    {
      id: 5,
      title: 'Geheimtipps',
      seoTitle: 'geheimtipps',
      image: 'tips200x200.jpg',
      imageBanner: 'secrets390x150.jpg',
      parent: 0,
    },
    {
      id: 6,
      title: 'Gruselige Orte',
      seoTitle: 'gruselige-orte',
      image: 'cruel200x200.jpg',
      imageBanner: 'cruel390x150.jpg',
      parent: 0,
    },
  ],
};

type State = typeof initialState;

export default function categoriesReducer(
  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  state = initialState,
  action: CategoriesActionTypes
): State {
  switch (action.type) {
    case ReduxAction.GET_CATEGORIES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
