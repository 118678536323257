import { createBrowserHistory } from 'history';
import { logger } from 'redux-logger';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducer from './reducer/index';

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) ||
  compose;

export const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function configureStore() {
  return createStore(
    reducer(history),
    composeEnhancers(
      applyMiddleware(routerMiddleware(history), promise, thunk, logger)
    )
  );
}
