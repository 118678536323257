import React, { useState } from 'react';
import './index.scss';

function PasswordText({ secret, setNextStep }): JSX.Element {
  const [passwordValue, setPasswordValue] = useState('');
  const [error, setError] = useState(false);

  function resetComponent() {
    setError(false);
    setPasswordValue('');
  }

  function checkPassword(event: React.ChangeEvent<HTMLInputElement>): void {
    setPasswordValue(event.target.value);

    if (event.target.value === secret) {
      resetComponent();
      setNextStep();
      return;
    }

    setError(true);
  }

  return (
    <div
      className={`password-text${
        error ? ' password-text--error' : ' password-text--user'
      }`}
    >
      <input
        className="password-text__input"
        type="text"
        placeholder="Passwort eingeben"
        onChange={(event) => checkPassword(event)}
        value={passwordValue}
      />
    </div>
  );
}

export default PasswordText;
