import {
  ReduxAction,
  NotificationActionTypes,
  NotificationNote,
  RemoveNote,
} from '../../types';
import createNote from '../../services/createNote';

export function setNotification(
  option: NotificationNote
): NotificationActionTypes {
  return {
    type: ReduxAction.SET_NOTIFICATION,
    payload: createNote(option),
  };
}

export function removeNotification(id: number): RemoveNote {
  return {
    type: ReduxAction.REMOVE_NOTIFICATION,
    payload: id,
  };
}
