import { CanvasActionTypes, CanvasState, ReduxAction } from '../../types';

const initialState: CanvasState = {
  canvasState: false,
};

type State = typeof initialState;

export default function toggleHandlerReducer(
  // eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
  state = initialState,
  action: CanvasActionTypes
): State {
  switch (action.type) {
    case ReduxAction.SET_CANVAS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
