import React, { useEffect, useLayoutEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import './index.scss';
import Message from './Message';
import Content from './Content';
import Help from './Help';
import TraceFooter from '../../containers/TraceFooter';
import GetTrace from '../../ApiHandler/GetTrace';
import GetContent from '../../ApiHandler/GetContent';
import checkLocationUndefined from '../../services/checkLocationUndefined';
import GetHelp from '../../ApiHandler/GetHelp';
import scrollIntoView from '../../services/scrollIntoView';
import CodeCheck from './CodeCheck';

function Trace({ location }): JSX.Element {
  const trace = useSelector(
    (state: RootStateOrAny) => state.traceReducer.traceCollection
  );
  const content = useSelector(
    (state: RootStateOrAny) => state.traceReducer.contentCollection
  );
  const products = useSelector(
    (state: RootStateOrAny) => state.productsReducer.products
  );
  const help = useSelector(
    (state: RootStateOrAny) => state.traceReducer.helpCollection
  );
  const [currentTraceStep, setCurrentTraceStep] = useState(0);
  const [traceStep, setTraceStep] = useState(trace?.traceStepsCollection);
  const [helpOutput, setHelpOutput] = useState([] as any);
  const [helpCount, setHelpCount] = useState(0);
  const [locationHelper, setLocationHelper] = useState(0);
  const traceStepLength = trace?.traceStepsCollection?.items?.length;
  const locationResult = checkLocationUndefined(location, products);

  GetTrace(locationResult);
  GetContent(locationResult);
  GetHelp(locationResult);

  let helpItems = help?.items[currentTraceStep]?.helpItemsCollection?.items;
  if (helpItems === undefined) {
    helpItems = [];
  }

  const contentItems =
    content?.items[currentTraceStep]?.contentItemsCollection?.items;

  useEffect(() => {
    const currentStepStorage = localStorage.getItem(`${locationResult}-trace`);
    if (currentStepStorage) {
      setCurrentTraceStep(parseInt(currentStepStorage, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (traceStepLength > 0) {
      setTraceStep(trace?.traceStepsCollection?.items[currentTraceStep]);
    }
  }, [trace, currentTraceStep, traceStepLength]);

  useLayoutEffect(() => {
    scrollIntoView('end');
  }, [helpCount]);

  useLayoutEffect(() => {
    scrollIntoView('start');

    if (currentTraceStep + 1 === traceStepLength) {
      localStorage.removeItem(`${locationResult}-trace`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTraceStep, traceStepLength]);

  const onClickHelp = () => {
    if (helpCount >= helpItems?.length) {
      return;
    }
    const helper = [...helpOutput, ...[helpItems[helpCount]]];

    setHelpOutput(helper);
    setHelpCount(helpCount + 1);
  };

  const setNextStep = () => {
    if (traceStepLength - 1 <= currentTraceStep) {
      return;
    }
    setHelpCount(0);
    setHelpOutput([]);
    setLocationHelper(0);
    setCurrentTraceStep(currentTraceStep + 1);
    localStorage.setItem(`${locationResult}-trace`, `${currentTraceStep + 1}`);
  };

  const callDistance = (value) => {
    setLocationHelper(value.toFixed(3));
  };

  return (
    <div className="trace">
      <div className="trace__banner">
        <img
          className="trace__banner-image"
          src={traceStep?.banner?.url}
          alt={traceStep?.banner?.title}
        />
      </div>

      <div className="trace__content">
        <Message headline="true" variant="answer">
          {traceStep?.title}
        </Message>
        {contentItems && <Content content={contentItems} />}

        <CodeCheck
          secretType={traceStep?.secretType}
          secret={traceStep?.secret}
          sortingList={traceStep?.sortingList}
          setNextStep={setNextStep}
          gpsLocation={traceStep?.location}
          callDistance={callDistance}
        />

        <Help helpOutput={helpOutput} />

        {helpItems.length === helpCount && locationHelper > 0 && (
          <div className="message message--answer">
            Noch {locationHelper} Meter zum Zielort
          </div>
        )}

        {helpItems.length === helpCount && (
          <div className="message message--answer">
            <div
              className="button-submit button-submit--fullscreen"
              onClick={setNextStep}
              onKeyUp={setNextStep}
              role="button"
              tabIndex={0}
            >
              Rätsel überspringen?
            </div>
          </div>
        )}
      </div>

      <TraceFooter onClickHelp={onClickHelp} helpCount={helpCount} />
    </div>
  );
}

export default Trace;
