import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

function CategoryBox({ category }): JSX.Element {
  return (
    <div className="category-box">
      <Link
        to={{
          pathname: `/kategorie/${category.seoTitle}`,
          state: { id: category.id },
        }}
      >
        <div className="category-box__image-wrapper">
          <img
            className="category-box__image"
            src={`${process.env.PUBLIC_URL}/images/category/${category?.image}`}
            alt={category?.title}
          />
        </div>
        <div className="category-box__content">
          <div className="category-box__title">{category?.title}</div>
        </div>
      </Link>
    </div>
  );
}

export default CategoryBox;
