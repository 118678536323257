import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './index.scss';
import Message from '../Message';
import renderOptions from '../../../services/renderOptions';

function Content({ content }): JSX.Element {
  return (
    <div className="content">
      {content[0]?.id &&
        content?.map((element) => {
          return (
            <Message variant="answer" key={`trace-content-${element.id}`}>
              {documentToReactComponents(
                element?.richText?.json,
                renderOptions(element?.richText?.links)
              )}
            </Message>
          );
        })}
    </div>
  );
}

export default Content;
